.scheme_container{
    position: absolute;
    left:0%;
    top: 0%;
    display: grid;
    grid-template-rows: 60px auto 55px;
    width: 100%;
    height: 100%;
    font-family: "Arial";
    overflow: hidden;
    // background-color: #d3d3d34f;

    .scheme_attachment_container{
        position: relative;
        display: grid;
        grid-template-rows: auto;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .list_blocks_container{
            position: relative;
            width: 100%;

            .lists_container{
                box-sizing:border-box;
                scrollbar-width: none;
                scrollbar-color: orange white;

            }
        }

    }

    .graph_block{
        //text-align: center;
        padding-bottom: 10px;

        .yellow_shade11{
            position: relative;
            display: grid;
            grid-template-columns: max-content auto;
            align-content: center;
            height:40px;
            width: 100%;
            background-color: #FFFFFF;
            
            .back_button{
              margin-left: 10px;
              color: black;
            }

            .desigtn_block{
                position: relative;
                box-sizing:border-box;
                width: 100%;
                height: auto;
                margin-left: 5px;
                color: #fd7e14;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1.1px;

            }
        }

        .yellow_shade2{
            position: relative;
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: white;

        }
        .sale_graph_container2{
            position: relative;
            display: inline-block;
            width: 85%;
            height: auto;
            color: #333;
            text-align: left;
            font-size: 14px;
            padding: 8px;
            box-sizing:border-box;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);

            .table_list2{
                border-collapse: collapse;
                width:100%;
                font-size: 10px;
                th{
                    padding: 10px 5px 10px 5px;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 12px;
                    background-color: #6498be;

                    // background-color: #cf3832;
                }

                td {
                    padding: 10px 5px 10px 5px;
                    box-sizing: border-box;
                    letter-spacing: 0.5px;
                    overflow: hidden;
                    white-space: normal;
                    max-width: 1.2rem;
                }
                
                tr:nth-child(odd) {
                    background-color: white;
                    color: black;
                }
    
               
                tr:nth-child(even) {
                    background-color: #ececec ;
                }

                .asm_download_button{
                    width: 100%;
                    align-self:center;
                    color: rgb(136, 16, 96);
                    cursor: pointer;
                    font-size: 10px;
                    padding : 15px;
                    
                    i{
                        color: #fd7e14;
                    }
                   
                }
            }
        }
    }
}
