.customer_outstanding_page_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";
  .list_blocks_container{
    .lists_container{
      scrollbar-width: thin;
      scrollbar-color: orange white;
      vertical-align: top !important;
    }
  }

  .toggles_container{
    position: relative;
    padding:8px auto;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns:repeat(2, 50%);
    // grid-columns-gap:5px;
    justify-items:center;
    background-color: #d3d3d35e;

    .toggles{
      display: grid;
      width:100%;
      // border:1px solid lightgrey;
      height: 70px;
      justify-items:center;
      grid-template-rows: auto auto auto;
      grid-row-gap:3px;
      align-content:center;
      color: #333;
      transition:all 0.3s;
      cursor: pointer;

      .order_status_icon{
        font-size: 22px;
      }
      
      .order_status{
        font-size: 10px;
        font-weight: 600;
      }

      .order_count{
        font-size: 12px;
        font-weight: 600;
      }
    }
   
  }

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .search_block{
    display: grid;
    grid-template-columns:auto auto;
    gap : 20px;
    padding : 5px 0px;

    .search_container{
      position: relative;
      display: grid;
      grid-template-columns:max-content auto;
      width: 180px;
      height: 38px;
      overflow: hidden;
      border: 1px solid grey;
      border-radius: 25px;
      max-width: 95%;
      margin-left: 10px;
      margin-top: 5px;
      align-items:center;
      color: grey;
      padding-left: 8px;
      box-sizing:border-box;

      .customer_search_input{
        padding: 4px 7px;
        box-sizing:border-box;
        width: 100%;
        height: 100%;
      }
    }
  }

  .executive_district_form{
    position: relative;
    width:100%;
    margin-top:10px;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      padding: 10px;
      box-sizing:border-box;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_grid{
        position: relative;
        display: grid;
        // grid-template-columns:50% 50%;
        grid-template-rows:auto auto;
        grid-column-gap:10px;
      }

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .submit_button_container{
        position: relative;
        margin-top: 5px;
        text-align: right;
        align-content: flex-end;
        display: grid;
        padding: 10px;

        .submit_button{
          background-color: orangered;
          color: white;
          border: 1px solid orangered;
          border-radius: 5px;
          padding: 5px 10px;
          transition: all 0.3s;
          cursor: pointer;

          &:hover{
            background-color: #d0410c;
            color: lightgrey;
          }
        }
      }
    }

    .executive, .district{
      font-size: 13px;
      .css-yk16xz-control{
        height: 32px!important;
        min-height: auto;
        border: none;
        border-radius: 0px;
        border-bottom: 2px solid #e9e8e8;
        background-color: transparent;

      }

      .css-g1d714-ValueContainer{
        padding: 0px;
      }

      .css-1pahdxg-control{
        box-shadow: none;
        border: none;
        height: 32px!important;
        min-height: auto;
        border-radius: 0px;
        border-bottom: 2px solid #e9e8e8;
        background-color: transparent;

        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .pholder,.css-tj5bde-Svg{
        box-shadow: none;
      }

      .css-1okebmr-indicatorSeparator{
        visibility: hidden;
      }
    }

  }
  .cost_center_outstandings_table{
    position: relative;
    width:90%;
    font-size: 10px !important;
    text-align: left;
    margin-top: 3px;
    border-collapse: collapse;
    border:1px solid grey;
    padding: 5px;
    margin: 10px !important;
    thead{
      color: white;
      font-size: 10px;
      background-color:#420b3e;
      tr{
        font-size: 8px;
        th{
          border:2px solid grey;
          padding: 5px;
        }
        td{
          padding: 5px;
        }
      }   
    }
    tbody{
      border: 1px solid black;
      font-size: 10px;

      tr{
        border: 1px solid black;

        td{
          padding: 5px;
          border: 1px solid black;

          
        }
      }
    }
   

  }

  .table_container{
    position: relative;
    width:100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    box-sizing:border-box;

    .customer_outstandings_table{
      position: relative;
      width:100%;
      font-size: 10px;
      text-align: left;
      margin-top: 3px;
      border-collapse: collapse;
      border:1px solid grey;

      thead{
        color: white;
        font-size: 14px;
        background-color:#420b3e;

        th{
          border:2px solid grey;
            padding: 5px;
        }
      }

      tbody{
        padding: 5px;
        box-sizing:border-box;
        font-size: 10px;
        color: #333;
        text-shadow: 0px 0px 3px white;

        .dealerTotal {
          border-top: 1px solid gray !important;
        }

        tr{
          td{
            border:1px solid grey;
            // border:1px solid grey;
            padding: 5px;
            white-space: normal;
            font-weight: 500;

            &:nth-child(2){
              word-break: break-all;
             }
          }

          &:nth-child(even){
            background-color: #80808014;
          }
        }
      }
      .finalTotal{
        text-align: center;
        td{
          span{
          font-size: 10px;
          font-weight: 700;
          }
        }
      }
    }

    .bills_table_container{
      position: relative;
      height: auto;
      overflow: hidden;
      transition: height 0.3s;
      text-shadow: 0px 0px 3px white;
      font-size: 10px !important;



      .bills_table{
        border-collapse: collapse;
        border: none;
        width: 100%;
        // background-color: #f4dcdf;
        thead{
          color: #333;
          font-size: 10px;
          background-color: transparent;

          th{
            border: none;
            border-bottom:1px solid #a8a8a8;
            text-align: center;
              padding: 3px 2px;
              &:nth-child(1){
                border-right:1px solid #a8a8a8;
              }
          }
        }

        tbody{
          padding: 5px;
          box-sizing:border-box;
          font-size: 8.8px;
          color: #333;
          tr{
          border:none;

            transition:all .3s;
            td{
              border:none;
              padding: 3px 2px;
              white-space: normal;
              font-weight: 500;
              text-align: right;

              &:nth-child(1){
                border-right:1px solid #a8a8a8;
                text-align: center;
              }
              &:nth-child(2){
                text-align: center;
              }
            }

            &:nth-child(even){
              background-color: transparent;
            }
          }
        }
        .bill_row_cell{
          width: 100%;

          tr{
            border: 1px solid black;
            td{
              border: none;
            }
            th{
              border: none;
              text-align: center;
            }
          }
          td{
            border: none;
          }
        }

        .dealerTotal{
          td{
            font-size: 10px;
            font-weight: 700;
          }
        }
      
      }
    }
  }

}