.new_secondary_sale_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .schedule_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .custom_input2{
        position: relative;
        width: 50%;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 1px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
      }
    }

    .customer_details_container{
      margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .customer_type_radio_buttons{
        display: grid;
        grid-template-columns:auto auto;
        justify-content:center;
        grid-column-gap:10px;
        margin-top: 10px;

        .label_radio_container{
          display: grid;
          grid-template-columns:max-content auto;
          align-items:center;
          grid-column-gap:2px;
          color: #333;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
            }
        }
      }

      .input{
        outline: none;
        box-shadow: none;
        resize:none;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }

      .add_button_container{
        position: relative;
        margin-top: 8px;
        width: 100%;
        text-align: right;
        
        .add_schedule_button{
          position: relative;
          display: inline-block;
          padding: 10px 20px;
          background-color: green;
          color: white;
          box-shadow: none;
          border: none;
          border-radius: 25px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;
          &:hover{
            background-color: #002400;
            color: lightgrey;
          }
        }
      }
      .location_container{
        position: relative;
        display: grid;
        grid-template-columns:auto max-content;
        grid-column-gap:5px;
  
        .location{
          position: relative;
          width: 100%;
          height: 26px;
          font-size: 12px;
          border:none;
          border-bottom: 2px solid #e9e8e8;
          caret-color: #fd7e14;
          font-family: "Arial";
          background-color: transparent;
  
  
          &:focus-within{
            border-color: #fd7e14;
          }
        }
  
        .location_button{
          align-self:center;
          padding:5px;
          color: #398ee7;
          font-size: 22px;
        }
      }
  

            

    }

    .schedules_table_container{
      
      table{
        position: relative;
        width: 100%;
        border-collapse: collapse;

        thead{
          font-size: 14px;
        }

        tbody{
          font-size: 12px;
        }

        tr{
          border-bottom: 1px solid lightgrey;

          td,th{
            padding: 10px 3px;

          }
        }

        tbody tr:nth-child(even){
          background-color: #8080802e;
        }

        .remove_schedule_button{
          font-size: 20px;
          color: red;
        }
      }
    }

    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

  .customer_container{
    position: relative;

  .customer_suggestions_container{
    display: none;
    position: absolute;
    top: 102%;
    left:0%;
    width: 70%;
    height: auto;
    background-color: white;
    border-top:1px solid grey;
    border-left:1px solid grey;
    border-right:1px solid grey;
    border-radius: 3px;
    z-index: 2022;

    .single_customer_details{
      position: relative;
      width: 100%;
      font-size: 13px;
      padding: 5px;
      box-sizing:border-box;
      border-bottom: 1px solid lightgrey;
      color: #333;
      font-family: "Arial";

      &:hover{
        background-color: lightblue;
        color: white;
      }
    }
  }
}

}