.schedule_details_page_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    background-color: #0c0a3c;
    color: white;
    box-sizing:border-box;

    .page_title{
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      color: white;
      text-decoration: none;
    }
  }

  .customer_details{
    position: relative;
    width:100%;
    height: auto;
    padding: 10px 0px;
    box-sizing:border-box;
    background-color: #0c0a3c;
    text-align: center;

    .customer_name{
      font-size: 22px;
      color: white;
      font-weight: 500;
      // padding-bottom: 10px;
    }

    .outstanding_sales_container{
      position: relative;
      display: none;
      grid-template-columns:auto auto;
      grid-column-gap:20px;
      justify-content:center;
      width: 100%;

      .outstanding{
        padding: 10px;
        // background-color: yellow;
        color:white;
        .amount{
          font-size: 18px;
          font-weight: 500;
        }

        .label{
          font-weight: 500;
          font-size: 12px;
        }
      }

      .sales{
        padding: 10px;
        // background-color: green;
        color:white;

        .amount{
          font-size: 18px;
          font-weight: 500;
        }

        .label{
          font-size: 12px;
        }
      }
    }
  }

  .schedule_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_label{
        position: relative;
        margin:8px 0px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .custom_input2{
        position: relative;
        width: 50%;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 1px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
      }
    }

    .schedule_details_container{
      // margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .input{
        outline: none;
        box-shadow: none;
        resize:none;
      }

      .purposes{
        .css-yk16xz-control{
              height: 32px!important;
              min-height: auto;
              border: none;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;
            }

            .css-g1d714-ValueContainer{
              padding: 0px;
              background-color: transparent;

            }

            .css-1pahdxg-control{
              box-shadow: none;
              border: none;
              height: 32px!important;
              min-height: auto;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

              &:focus-within{
                border-color: #fd7e14;
              }
            }

            .pholder,.css-tj5bde-Svg{
              box-shadow: none;
            }

            .css-1okebmr-indicatorSeparator{
              visibility: hidden;
            }
      }
    }

    .location_container{
      position: relative;
      display: grid;
      grid-template-columns:auto max-content;
      grid-column-gap:5px;

      .location{
        position: relative;
        width: 100%;
        height: 26px;
        font-size: 12px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .location_button{
        align-self:center;
        padding:5px;
        color: #398ee7;
        font-size: 22px;
      }
    }

    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

}