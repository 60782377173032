.loading_container {
	position: fixed; /* Ensures the container covers the entire viewport */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.3);
	z-index: 2021;
	display: flex;
	justify-content: center;
	align-items: center;
	.loading_symbol {
		width: 80px; /* Adjust based on the SVG size */
		height: 80px; /* Adjust based on the SVG size */
		display: flex;
		justify-content: center;
		align-items: center;
	  }
}
  

  
  