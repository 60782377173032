.home_container{
  position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
  width: 100%;
  height: 100%;
  font-family: "Arial";
  overflow-y: scroll;

  background-color: #d3d3d34f;

  .home_dashboard_container{
    position: relative;
    display: grid;
    grid-template-rows: auto;
    width: 100%;
    height: 100%;
    // overflow-y: scroll;

  
    .toggles_container{
      position: relative;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(2,50%);
      justify-items:center;
      background-color:#420b3e;
      display: none;

      .toggles{
        width:100%;
        padding-bottom: 5px;
        box-sizing: border-box;
        height: auto;
        text-align:center;
        color:#333;
        transition:all 0.3s;
        cursor: pointer;
        border-bottom: 3px solid #420b3e;
        color:white;
        opacity: 0.8;
      }
      .toggles.selected{
        background-color:#420b3e;
        border-bottom: 3px solid #ff6384;
        color: white;
        opacity: 1;
      }

    }


    .list_blocks_container{
      position: relative;
      width: 100%;
      overflow-y: scroll;

      .lists_container{
        box-sizing:border-box;
        scrollbar-width: none;
        scrollbar-color: orange white;
        .designation_header {
          padding: 6px 0px;
          display: grid;
          grid-template-columns: auto auto;
          margin-bottom: 5px;
          div{
          font-size: small ;
          }
        
          
        }
        .exe_header{
          display: grid;
          grid-template-columns: 40% auto;
          // justify-items:center;
          grid-column-gap: 10px;
          background-color:#000000;
          padding: 6px 10px;
          font-size: 14px;
          
          .exe_name{
            margin : auto;
            color : white
          }
          .exe_div{
            margin: 5px;

          }
          .exe_select {
            text-align: left;
            .css-yk16xz-control{
              height: 32px!important;
              min-height: auto;
              border: none;
              border-radius: 0px;
              // border-bottom: 2px solid #e9e8e8;
              background-color: transparent;
              color: #ffffff;
      
            }
      
            .css-g1d714-ValueContainer, .css-1uccc91-singleValue{
              padding: 0px;
              color: #ffffff;
              // justify-content: center;
            }
      
            .css-1pahdxg-control{
              box-shadow: none;
              border: none;
              height: 32px!important;
              min-height: auto;
              border-radius: 0px;
              // border-bottom: 2px solid #e9e8e8;
              background-color: transparent;
              color: #ffffff;
      
              // &:focus-within{
              //   border-color: #fd7e14;
              // }
            }
            .css-tj5bde-Svg{
              color: #ffffff;
            }
      
            .pholder,.css-tj5bde-Svg{
              box-shadow: none;
            }
      
            .css-1okebmr-indicatorSeparator{
              visibility: hidden;
            }
          }
        }
      }
      .graph_block{
        text-align: center;
        padding-bottom: 10px;
    
        .yellow_shade1{
          position: relative;
          height:60px;
          width: 100%;
          // background-color: #9bb909;
          // background-color: #b9097c;
          background-color:#bf1d1d;
    
          .current_month{
            position: relative;
            padding: 20px;
            box-sizing:border-box;
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: white;
          }
        }
        .margin_bottom{
          margin-bottom:15px
        }
  
        .yellow_shade2{
          position: relative;
          width: 100%;
          height: auto;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          color: white;
          // background-color: blue; 

         
          .yellow_shade3{
            position: absolute;
            top:0%;
            left: 0%;
            width: 100%;
            height: 60px;
            // background-color: #9bb909;
            background-color: #bf1d1d;
    
          }
    
          .sale_target_container{
            position: relative;
            display: inline-block;
            width: 85%;
            height: auto;
            color: #333;
            text-align: left;
            font-size: 14px;
            padding: 5px;
            box-sizing:border-box;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
            // padding-bottom: 20px;
    
            table{
              width: 100%;
              border-collapse: collapse;
              font-size: 12px;
              thead{
                tr:first-child{
                  background-color: #fff;
                  color: black;
                }
                th{
                  padding: 10px 8px 10px 8px;
                  box-sizing: border-box;
                  text-align: center;
                  border-right: 2px solid #333;
                  letter-spacing: 0.5px;
                  font-size: 11px;
    
                }
                .target-heading{
                  width: 130px;
                }
                th:last-child{
                  border-right: none;
                }
                .second-heading{
                  max-width : 100px
                }
                
              }
    
              tbody{
                tr{
    
                  td{
                    padding: 10px 8px 10px 8px;
    
                    text-align: center;
                    border-right: 2px solid #333;
                    
                  }
                  td:first-child{
                    width: 100px;
                  }
                  td:last-child{
                    border-right: none;
                  }
                }
              }
    
            }
          }
          
        }
        .secondary{
          margin-top:  20px;
        }
    
        .total_outstanding_container {
          position: relative;
          display: inline-block;
          width: 85%;
          height: auto;
          color: #333;
          text-align: left;
          font-size: 14px;
          padding: 5px;
          box-sizing: border-box;
          border-radius: 10px;
          background-color: white;
          box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
          margin-top: 20px;
          // padding-bottom: 20px;
          table{
            width: 100%;
            border-collapse: collapse;
            overflow: hidden;
            tbody{
              tr{
                td {
                  padding: 12px;
                  box-sizing: border-box;
                  text-align: center;
                  letter-spacing: 0.5px;
                  overflow: hidden;
                  white-space: normal;
                }
                td:first-child{
                 font-size: 12px;
                 font-weight: 600;
                //  letter-spacing:px;
                 text-transform: uppercase;
                }
                td:last-child{
                  text-align: right;
                }
    
              }
            }
          }
    
        }
    
    
    
        .collection_graph_container {
          position: relative;
          display: inline-block;
          margin-top: 20px;
          height: auto;
          width: 85%;
          color: #333;
          text-align: left;
          font-size: 14px;
          padding: 5px;
          box-sizing: border-box;
          border-radius: 10px;
          font-weight: 600;
          background-color: white;
          box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
          overflow-x: auto;
          margin-bottom: 20px;
          
          // padding-bottom: 20px;
          table{
            width: 100%;
            border-collapse: collapse;
            thead{
              tr:first-child{
                background-color: #fff;
                color: black;
              }
              th {
                letter-spacing: 0px;
                font-size: 12px;
                padding: 10px 8px;
                text-align: center;
                border-right: 2px solid #333;
              }
              th:last-child{
                border-right: none;
              }
              
            }
            tbody{
              font-size: 12px;
             
              td:first-child{
                // width: 80px;
                text-align: left;
                font-size: 12px;
              }
              td{
                padding: 8px 10px 8px 10px;
                font-size: 11px;
                letter-spacing: 0.6px;
                border-right: 2px solid #333;
                text-align: right;
              }
              td:last-child{
                border-right: none;
    
              }
            }
          }
    
        
        }
    
        td:nth-child(even){
          word-break:keep-all;
          letter-spacing: 0px;
        } 
        td:nth-child(2){
          padding-right:2px;
        } 
        td:nth-child(3){
          padding-left: 0px;
        } 
        tr:nth-child(odd) {
          background-color: #0293ea;
          // background-image: linear-gradient(to right, #f88c08, #b20a2c);
          // background-image: linear-gradient(to right, #00b4db, #0083b0);
          // background-image: linear-gradient(25deg,#d64c7f,#ee4758 50%);
          // background-image: linear-gradient(to right, #141e30, #243b55);
         
    
          color:#fff;
          .fa-rupee-sign{
            color:#fff;
          }
        }
        tr:nth-child(even){
          .fa-rupee-sign{
            color:#333;
          }
        }
        div{
            .pushable {
              position: relative;
              border: none;
              background: transparent;
              padding: 0;
              cursor: pointer;
              outline-offset: 4px;
              transition: filter 250ms;
            }
            .shadow {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              background: hsl(0deg 0% 0% / 0.25);
              will-change: transform;
              transform: translateY(2px);
              transition:
                transform
                600ms
                cubic-bezier(.3, .7, .4, 1);
            }
            .edge {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              background: linear-gradient(
                to left,
                hsl(340deg 100% 16%) 0%,
                hsl(340deg 100% 32%) 8%,
                hsl(340deg 100% 32%) 92%,
                hsl(340deg 100% 16%) 100%
              );
            }
            .front {
              display: block;
              position: relative;
              padding: 8px 18px;
              border-radius: 12px;
              font-size: 1rem;
              color: white;
              background: rgb(255, 2, 2);
              will-change: transform;
              transform: translateY(-4px);
              transition:
                transform
                600ms
                cubic-bezier(.3, .7, .4, 1);
            }
            .pushable:hover {
              filter: brightness(110%);
            }
            .pushable:hover .front {
              transform: translateY(-6px);
              transition:
                transform
                250ms
                cubic-bezier(.3, .7, .4, 1.5);
            }
            .pushable:active .front {
              transform: translateY(-2px);
              transition: transform 34ms;
            }
            .pushable:hover .shadow {
              transform: translateY(4px);
              transition:
                transform
                250ms
                cubic-bezier(.3, .7, .4, 1.5);
            }
            .pushable:active .shadow {
              transform: translateY(1px);
              transition: transform 34ms;
            }
            .pushable:focus:not(:focus-visible) {
              outline: none;
            }
        }
      }
    }

  }

  



}