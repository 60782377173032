.new_marketing_attendance_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";
  // overflow: hidden;
  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .products_container{
    padding: 10px;
    box-sizing:border-box;

    .single_product_container{
      position: relative;
      border-bottom: 2px solid #420b3e;
      border-top: 2px solid #420b3e;
      padding: 0px 7px 7px 7px;
      box-sizing:border-box;
      margin-top: 10px;
      // background-color: #e6bee2;
      background:linear-gradient(to bottom right , rgba(255,255,0,0.1), rgba(0,255,255,0.1));
      transition : all 1s;

      .product_label_and_delete_button{
        display: grid;
        height: 26px;
        width:100%;
        grid-template-columns: max-content auto max-content;
        align-items:center;
        // padding: 0px 5px;
        box-sizing:border-box;

        grid-column-gap:5px;

        .product_label{
          font-size: 14px;
          height: 16px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap;
          color: green;
        }

        .maximize_button{
          display: none;
        }

        .minimize_button,.maximize_button{
          color: #420b3e;
          padding: 3px 3px 3px 0px;
          box-sizing:border-box;
        }

        .delete_product_button{
          justify-self:right;
          color: red;
          box-shadow: none;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;
          // border: 1px solid white;
          // &:hover{
          //   border: 1px solid red;
          // }

        }

      }

      .qty_unit_price_container{
        position: relative;
        display: none;
        grid-template-columns:auto auto auto;
        font-size: 12px;

      }

      .input_label{
        margin-top: 6px;
      }

      .product{
        font-size: 13px;
          .css-yk16xz-control{
            height: 32px!important;
            min-height: auto;
            border: none;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;

          }

          .css-g1d714-ValueContainer{
            padding: 0px;
          }

          .css-1pahdxg-control{
            box-shadow: none;
            border: none;
            height: 32px!important;
            min-height: auto;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;

            &:focus-within{
              border-color: #fd7e14;
            }
          }

          .pholder,.css-tj5bde-Svg{
            box-shadow: none;
          }

          .css-1okebmr-indicatorSeparator{
            visibility: hidden;
          }
      }
      

      .input{
        width:100%;
        outline: none;
        box-shadow: none;
        resize:none;
        -moz-appearance: textfield;
      }

      .unit{
        border: none;
        height: 32px;
        font-size: 12px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;

        .css-yk16xz-control{
          height: 32px!important;
          min-height: auto;
          border: none;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;
        }

        .css-g1d714-ValueContainer{
          padding: 0px;
          background-color: transparent;

        }

        .css-1pahdxg-control{
          box-shadow: none;
          border: none;
          height: 32px!important;
          min-height: auto;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

          &:focus-within{
            border-color: #fd7e14;
          }
        }

        .pholder,.css-tj5bde-Svg{
          box-shadow: none;
        }

        .css-1okebmr-indicatorSeparator{
          visibility: hidden;
        }
      }
    }

    .price_and_add_button_container{
      position: relative;
      display: grid;
      grid-template-columns:auto max-content;
      margin-top: 5px;
      align-items:center;

      .add_product_button{
        position: relative;
        justify-self:right;
        padding: 10px 20px;
        background-color: green;
        color: white;
        box-shadow: none;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        text-decoration: none;
        transition:all 0.3s;
        &:hover{
          background-color: #002400;
          color: lightgrey;
        }
      }

    }
  }

  .dealer_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_grid{
        position: relative;
        display: grid;
        grid-template-columns:50% auto;
        grid-column-gap:10px;
      }

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }
      .input_label_head {
        position: relative;
        padding-top: 8px;
        padding-left: 8px;
        color: #ff6384;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .custom_input_date{
        position: relative;
        width: 230px;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }
    }

    .product_details_container{
      margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .input{
        outline: none;
        box-shadow: none;
        resize:none;
        -moz-appearance: textfield;


        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }


      .input_radio_container{
        position: relative;
        display: grid;
        margin-top: 10px;
        grid-template-columns:max-content auto;
        grid-column-gap:10px;
        align-items:center;
        font-size: 14px;
        color: #617973;
        font-family: "Arial";

        .lead_type_radio_buttons{
          display: grid;
          grid-template-columns:auto auto;
          justify-content:left;
          grid-column-gap:10px;
          align-items:center;

          .label_radio_container{
            display: grid;
            grid-template-columns:max-content auto;
            align-items:center;
            grid-column-gap:4px;
            font-size: 12px;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
            }
          }
        }
      }

      .category, .brand,.product{
        font-size: 13px;
        .css-yk16xz-control{
          height: 32px!important;
          min-height: auto;
          border: none;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

        }

        .css-g1d714-ValueContainer{
          padding: 0px;
        }

        .css-1pahdxg-control{
          box-shadow: none;
          border: none;
          height: 32px!important;
          min-height: auto;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

          &:focus-within{
            border-color: #fd7e14;
          }
        }

        .pholder,.css-tj5bde-Svg{
          box-shadow: none;
        }

        .css-1okebmr-indicatorSeparator{
          visibility: hidden;
        }
      }


      .checkbox_label_container{
        display: grid;
        grid-template-columns:max-content auto;
        grid-column-gap : 5px;
        align-items:center;
        margin-top: 5px;

        input[type="checkbox"]{
          width: 18px; /*Desired width*/
          height: 18px; /*Desired height*/
        }

        .checkbox_label{
          font-size: 14px;
          color: #333;
        }
      }
      

    }


    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
    .sale_volume_of_each_category input[type='number']{
			position: relative;
			width:100px;
			height:30px;
			font-size: 14px;
			border-radius: 4px;
			border: 1px solid #ff6384;
      margin-right: 20px;
      -moz-appearance:textfield;
      padding: 10px;
      box-sizing: border-box;
		}

    .single_category_container {
      margin-bottom: 0px;
      padding-left: 5px;
      padding-bottom: 10px;
      align-items: center;
    }
    .label_checkbox_container{
			display: grid;
			grid-template-columns: auto max-content;
			margin-bottom: 7px;
			padding-left: 9px;
		}

    .file_upload{
      width: 100%;
    }
    .thumbnail {
      width:50px;
      height:50px;
      position:relative;
      }
      
      .thumbnail img {
      height: 100px;
        position: relative;
        border: 1px solid #ff6384;
        margin-top: 10px;
      }
      
      .thumbnail i {
        margin: 0;
        position: absolute;
        top: -70px;
        right: 5px;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }   
  }

  .customer_container{
    position: relative;

  .customer_suggestions_container{
    display: none;
    position: absolute;
    top: 102%;
    left:0%;
    width: 70%;
    height: auto;
    background-color: white;
    border-top:1px solid grey;
    border-left:1px solid grey;
    border-right:1px solid grey;
    border-radius: 3px;
    z-index: 2022;

    .single_customer_details{
      position: relative;
      width: 100%;
      font-size: 13px;
      padding: 5px;
      box-sizing:border-box;
      border-bottom: 1px solid lightgrey;
      color: #333;
      font-family: "Arial";

      &:hover{
        background-color: lightblue;
        color: white;
      }
    }
  }
}
.list_blocks_container{
  position: relative;
  width: 100%;
  height: auto;
  // scrollbar-width: thin;
  //   scrollbar-color: orange white;
  //   overflow-y: scroll;

  .lists_container{
    padding: 10px;
    box-sizing:border-box;
    


    .single_collection_container{
      position: relative;
      display: block;
      width:100%;
      margin-bottom: 10px;
      padding: 12px;
      box-sizing:border-box;
      height: auto;
      overflow: hidden;
      // background-color: #0080003d;
      // background-color: #ea8000;
      // background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
      // background-image: linear-gradient(315deg, #ea8000 0%, #3e4571 74%);
      // background-image: linear-gradient(315deg, #ea8000 0%, #420b3e 74%);
      background-image: linear-gradient(315deg, #420909 0%, #2f2f2f 74%);
      transition: 0.6s height;
      // border-radius: 7px;
      border-radius: 5px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .collection_customer_name_delete_button_container{
        position: relative;
        width: 100%;
        display: grid;
        // grid-template-columns:max-content auto max-content;
        // grid-column-gap:10px;
        box-sizing:border-box;

        .collection_date_container{
          text-align: center;
          display: flex;
          align-items:center;
          border-right: 2px solid #9c4a1d;
          padding: 0px 15px 0px 6px;
          height: 100%;
          // background-color: black;
          .collection_no{
            font-size: 26px;
            font-weight: 600;
            color: white;
          }

        }
        .collection_customer_name{
            position: relative;
            font-weight: 500;
            color: white;
            font-size: 18px;
            white-space: pre-line;
            color: white;
            text-shadow: 0px 0px 1px white;
          }
        
        .attendance_details_status_button_container{
          position: relative;
          display: grid;
          grid-template-columns:auto auto;
          align-items:center;
          grid-column-gap:20px;

          

          .receipt_no, .col_date, .amount{
            color: white;
            font-size: 16px;
          }

          .amount{
            font-weight: 600;
            // color:black;
            // text-shadow:0px 0px 1px white;
          }

          .collection_status{
            padding: 5px 8px;
            // border:1px dashed #d0ff00;
            // background-color: white;
            color:white;
            border-radius: 8px;
            background-color: green;
            font-size: 14px;
            font-weight: bold;
            text-align: center;

            .fa-check{
              color: rgb(255, 255, 255);
              margin-right: 10px;
            }
          }

          .edit_button{
            margin-left: 20px;
            display: inline-block;
            padding: 5px 8px;
            background-color: white;
            border:2px solid #b57741;
            color: #b57741;
            border-radius: 6px;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .leave_details_status_button_container{
          position: relative;
          display: grid;
          grid-template-columns:45% 10% 45%;
          // align-items:center;
          grid-column-gap:5px;
          padding: 10px 0px;
          .leave_date{
            position: relative;
            font-weight: 600;
            color: white;
            font-size: 14px;
            // white-space: pre-line;
            color: white;
            letter-spacing: 1.2px;
            text-shadow: 0px 0px 1px white;
          }
          .divider{
            color: white;
            font-size: 16px;
          }

          .receipt_no, .col_date, .amount{
            color: white;
            font-size: 16px;
          }

          .amount{
            font-weight: 600;
            // color:black;
            // text-shadow:0px 0px 1px white;
          }

          .collection_status{
            padding: 5px 8px;
            // border:1px dashed #420b3e;
            // background-color: white;
            color:white;
            border-radius: 8px;

            .fa-check{
              color: green;
              margin-right: 10px;
            }
          }

          .edit_button{
            margin-left: 20px;
            display: inline-block;
            padding: 5px 8px;
            background-color: white;
            border:2px solid #b57741;
            color: #b57741;
            border-radius: 6px;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .leave2_details_status_button{
          position: relative;
          display: grid;
          grid-template-columns:50% 50%;
          align-items:center;
          grid-column-gap:5px;
          padding: 10px 0px;


          .leave_status_approved{
            padding: 5px 8px;
            // border:1px dashed #22fa51;
            background-color: white;
            color:#006c17;
            border-radius: 8px;
            font-size: 14px;  
            font-weight: bold;
            text-align: center;

            .fa-check{
              color: green;
              margin-right: 10px;
            }
          }
          .leave_status_requested{
            padding: 5px 8px;
            // border:1px dashed #000000;
            background-color: rgb(255, 255, 255);
            color:rgb(255, 0, 0);
            border-radius: 8px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;

            .fa-clock-o{
              color: rgb(255, 0, 0);
              margin-right: 10px;
            }
          }
          .leave_status_rejected{
            padding: 5px 8px;
            // border:1px dashed #ffffff;
            background-color: rgb(255, 0, 0);
            color:rgb(255, 255, 255);
            border-radius: 8px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;

            .fa-times{
              color: rgb(255, 255, 255);
              margin-right: 10px;
            }
          }


        }
        .leave2_reason{
          position: relative;
          font-weight: 500;
          color: white;
          font-size: 16px;
          white-space: pre-line;
          color: white;
          // text-shadow: 0px 0px 1px white;
          padding: 5px 0px;
          letter-spacing: 1.2px;
          line-height: 26px;

          
        }
        .leave_type{
          position: relative;
          font-weight: 500;
          color: white;
          font-size: 16px;
        }
        .app{
          font-size: 16px;
          color: white;
        }

      }

    }
  }
}
}