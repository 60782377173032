.leave_approval_container{
  position: absolute;
 left:0%;
 top: 0%;
 display: grid;
 grid-template-rows: 60px auto 55px;
 width: 100%;
 height: 100%;
 font-family: "Arial";
 overflow-y: scroll;
 overflow-x: hidden;

 .travel_allowances_container{
   position: relative;
   display: grid;
   grid-template-rows: max-content max-content auto;
   width: 100%;
   height: 100%;

  
   .page_header{
     position: relative;
     width: 100%;
     // height: 50px;
     display: grid;
     grid-template-columns: 50% 50%;
     background-color: #ea8000;
     // border-bottom: 1px solid #333;
     align-items:center;
     padding:5px 5px 5px 10px;
     box-sizing:border-box;

     .page_title{
       color: white;
       font-size: 16px;
       font-weight: 500;
       letter-spacing: 1.5px;
     }

     .new_travel_allowance_button{
       color:white;
       font-size: 14px;
       font-weight: 500;
       font-family: "Arial";
       padding: 7px 16px;
       background-color: #058b00;
       border : 1px solid lightgrey;
       border-radius: 15px;
       cursor: pointer;
       text-decoration: none;

       &:hover{
         background-color: #058b71;
         color: lightgrey;
       }
     }
   }

   .current_month{
     margin-top: 10px;
     justify-self:center;
     font-size: 16px;
     font-weight: 600;
     color: #3e4571;
   }


   .list_blocks_container{
    position: relative;
    width: 100%;
    height: auto;
    // scrollbar-width: thin;
    //   scrollbar-color: orange white;
    //   overflow-y: scroll;
  
    .lists_container{
      padding: 10px;
      box-sizing:border-box;
     
  
  
      .single_collection_container{
        position: relative;
        display: block;
        width:100%;
        margin-bottom: 10px;
        padding: 12px;
        box-sizing:border-box;
        height: auto;
        overflow: hidden;
        // background-color: #0080003d;
        // background-color: #ea8000;
        // background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
        // background-image: linear-gradient(315deg, #ea8000 0%, #3e4571 74%);
        // background-image: linear-gradient(315deg, #ea8000 0%, #420b3e 74%);
        background-image: linear-gradient(315deg, #420909 0%, #2f2f2f 74%);
        transition: 0.6s height;
        // border-radius: 7px;
        border-radius: 5px;
        box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);
  
        .collection_customer_name_delete_button_container{
          position: relative;
          width: 100%;
          display: grid;
          // grid-template-columns:max-content auto max-content;
          // grid-column-gap:10px;
          box-sizing:border-box;
          .executive_name{
            font-size: 16px;
            color: #3fdb13;
          }
          .collection_date_container{
            text-align: center;
            display: flex;
            align-items:center;
            border-right: 2px solid #9c4a1d;
            padding: 0px 15px 0px 6px;
            height: 100%;
            // background-color: black;
            .collection_no{
              font-size: 26px;
              font-weight: 600;
              color: white;
            }
  
          }
          .collection_customer_name{
              position: relative;
              font-weight: 500;
              color: white;
              font-size: 18px;
              white-space: pre-line;
              color: white;
              text-shadow: 0px 0px 1px white;
            }
          
          .attendance_details_status_button_container{
            position: relative;
            display: grid;
            grid-template-columns:auto auto;
            align-items:center;
            grid-column-gap:20px;
  
            
  
            .receipt_no, .col_date, .amount{
              color: white;
              font-size: 16px;
            }
  
            .amount{
              font-weight: 600;
              // color:black;
              // text-shadow:0px 0px 1px white;
            }
  
            .collection_status{
              padding: 5px 8px;
              // border:1px dashed #d0ff00;
              // background-color: white;
              color:white;
              border-radius: 8px;
              background-color: green;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
  
              .fa-check{
                color: rgb(255, 255, 255);
                margin-right: 10px;
              }
            }
  
            .edit_button{
              margin-left: 20px;
              display: inline-block;
              padding: 5px 8px;
              background-color: white;
              border:2px solid #b57741;
              color: #b57741;
              border-radius: 6px;
              text-decoration: none;
              font-size: 14px;
              font-weight: 500;
            }
          }
  
          .leave_details_status_button_container{
            position: relative;
            display: grid;
            grid-template-columns:45% 10% 45%;
            // align-items:center;
            grid-column-gap:5px;
            padding: 10px 0px;
            .leave_date{
              position: relative;
              font-weight: 600;
              color: white;
              font-size: 14px;
              // white-space: pre-line;
              color: white;
              letter-spacing: 1.2px;
              text-shadow: 0px 0px 1px white;
            }
            .divider{
              color: white;
              font-size: 16px;
            }
  
            .receipt_no, .col_date, .amount{
              color: white;
              font-size: 16px;
            }
  
            .amount{
              font-weight: 600;
              // color:black;
              // text-shadow:0px 0px 1px white;
            }
  
            .collection_status{
              padding: 5px 8px;
              // border:1px dashed #420b3e;
              // background-color: white;
              color:white;
              border-radius: 8px;
  
              .fa-check{
                color: green;
                margin-right: 10px;
              }
            }
  
            .edit_button{
              margin-left: 20px;
              display: inline-block;
              padding: 5px 8px;
              background-color: white;
              border:2px solid #b57741;
              color: #b57741;
              border-radius: 6px;
              text-decoration: none;
              font-size: 14px;
              font-weight: 500;
            }
          }
  
          .leave2_details_status_button{
            position: relative;
            display: grid;
            grid-template-columns:50% 50%;
            align-items:center;
            grid-column-gap:5px;
            padding: 10px 0px;
  
  
            .leave_status_approved{
              padding: 5px 8px;
              // border:1px dashed #22fa51;
              background-color: white;
              color:#006c17;
              border-radius: 8px;
              font-size: 14px;  
              font-weight: bold;
              text-align: center;
  
              .fa-check{
                color: green;
                margin-right: 10px;
              }
            }
            .leave_status_requested{
              padding: 5px 8px;
              // border:1px dashed #000000;
              background-color: rgb(255, 255, 255);
              color:rgb(255, 0, 0);
              border-radius: 8px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
  
              .fa-clock-o{
                color: rgb(255, 0, 0);
                margin-right: 10px;
              }
            }
            .leave_status_rejected{
              padding: 5px 8px;
              // border:1px dashed #ffffff;
              background-color: rgb(255, 0, 0);
              color:rgb(255, 255, 255);
              border-radius: 8px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
  
              .fa-times{
                color: rgb(255, 255, 255);
                margin-right: 10px;
              }
            }
  
  
          }
          
          .leave2_reason{
            position: relative;
            font-weight: 500;
            color: white;
            font-size: 16px;
            white-space: pre-line;
            color: white;
            // text-shadow: 0px 0px 1px white;
            padding: 5px 0px;
            letter-spacing: 1.2px;
            line-height: 26px;
  
            
          }
          .leave_type{
            position: relative;
            font-weight: 500;
            color: white;
            font-size: 16px;
          }
          .app{
            font-size: 16px;
            color: white;
          }
  
        }
        .approve_cancel_leave{
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap:12px;
          margin-top: 6px;
        
          .leave_approve_button{
            align-self:center;
            color: #2eaa2e;;
            cursor: pointer;
            font-size: 18px;
            background-color: white;
            padding: 5px 2px;
            border-radius: 6px;
            border:2px solid #b57741;


            i{
              text-align: center;
              display: block;
            }

            span{
              text-align: center;
              display: block;
              font-size: 14px;
              font-weight:700;
            }

          }

          .leave_cancel_button{
            align-self:center;
            color: #ff0000;;
            cursor: pointer;
            font-size: 18px;
            background-color: white;
            padding: 5px 2px;
            border-radius: 6px;
            border:2px solid #b57741;


            i{
              text-align: center;
              display: block;
            }
            span{
              text-align: center;
              display: block;
              font-size: 14px;
              font-weight:700;
            }


          }
        }
  
      }

      .approve_all_cancel_all_leave{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap:12px;
        margin-top: 6px;
        margin-bottom: 12px;
      
        .leave_approve_all_button{
          align-self:center;
          color: #2eaa2e;;
          cursor: pointer;
          font-size: 18px;
          background-color: white;
          padding: 5px 2px;
          border-radius: 6px;
          border:2px solid #b57741;


          i{
            text-align: center;
            display: block;
            span{
              font-family: monospace;
            }
          }

        }

        .leave_cancel_all_button{
          align-self:center;
          color: #ff0000;;
          cursor: pointer;
          font-size: 18px;
          background-color: white;
          padding: 5px 2px;
          border-radius: 6px;
          border:2px solid #b57741;


          i{
            text-align: center;
            display: block;
            span{
              font-family: monospace;
            }
          }

        }
      }
    }
  }

 }

}