.new_travel_allowance_container{
  position: relative;
  visibility: hidden;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #ea8000;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .travel_allowance_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .date{
        font-weight: 600;
        color: #333;
      }

      .input_grid{
        position: relative;
        display: grid;
        grid-template-columns:50% auto;
        grid-column-gap:10px;
      }

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }
    }

   .date_container{
      padding: 10px 10px 10px 10px;
      box-sizing:border-box;

       .input{
        width: 100%;
        outline: none;
        box-shadow: none;
        resize:none;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }

    }
    .location_title{
      margin: 10px 0px 0px 0px;
      color: #420b3e;
      font-weight: 600;
      height: 18px;
      vertical-align: middle;
  
    }
    .calls_title{
      margin: 14px 0px 0px 0px;
      color: #420b3e;
      font-weight: 600;
      height: 18px;
      vertical-align: middle;
  
    }

    .location_text{
      width: 100%;
      resize: none;
      margin: 10px 0px;
      padding: 10px 10px 10px 10px;
      box-sizing: border-box; 
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.7), 0 1px 1px rgba(0, 0, 0, 0.7); 
      height:40px;
    }

    .input_grid{
      position: relative;
      display: grid;
      grid-template-columns:60% 30%;
      grid-column-gap:14px;
    }
    .num_calls{
      width: 100%;
      resize: none;
      margin: 10px 0px;
      padding: 5px 5px 5px 5px;
      box-sizing: border-box; 
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.7), 0 1px 1px rgba(0, 0, 0, 0.7); 
      height:26px;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      text-align: center;
    }



    .container{
      margin-bottom: 10px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .ta_date{
        padding: 5px 0px;
      }

      .block_title{
        display: inline-block;
        color: #420b3e;
        font-weight: 600;
        height: 18px;
        vertical-align: middle;
        margin-left: 5px;
      }

      input[type=checkbox] {
        width: 18px;
        height: 18px;
        margin:0px;
        margin-top: -1px;
        vertical-align: middle;
        transition:all 0.3s;
      }

      .allowance_details{
        height:0px;
        overflow: hidden;
        transition: all .5s;
      }

      .input{
        outline: none;
        box-shadow: none;
        resize:none;
        -moz-appearance: textfield;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }

      .kilometers{
        color: #333;
        font-weight: 600;
      }

      .type{
        font-size: 13px;
        .css-yk16xz-control{
          height: 32px!important;
          min-height: auto;
          border: none;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

        }

        .css-g1d714-ValueContainer{
          padding: 0px;
        }

        .css-1pahdxg-control{
          box-shadow: none;
          border: none;
          height: 32px!important;
          min-height: auto;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

          &:focus-within{
            border-color: #fd7e14;
          }
        }

        .pholder,.css-tj5bde-Svg{
          box-shadow: none;
        }

        .css-1okebmr-indicatorSeparator{
          visibility: hidden;
        }
      }

      .maximum_price{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 5px;
        font-size: 12px;
        color: #8ca2ad;
      }
      

    }

    .grand_total{
      border:4px solid #1a73e9;
      text-align: center;
      padding: 5px;
      box-sizing:border-box;
      font-weight: 600;
      letter-spacing: 1.5px;
      color: #333;
    }
    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

}