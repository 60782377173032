.targets_page_container {
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header {
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    box-sizing: border-box;

    .page_title {
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button {
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .executive_district_form {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding: 0px 10px 10px 10px;
    box-sizing: border-box;

    .blocks {
      position: relative;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0, 0, 0, .7), 0 1px 3px rgba(0, 0, 0, .7);

      .input_grid {
        position: relative;
        display: grid;
        grid-template-rows: auto auto;
        grid-column-gap: 10px;

        .dist_exe {
          display: grid;
          // grid-template-columns: ;
          grid-column-gap: 10px;

        }
      }

      .input_label {
        position: relative;
        margin-top: 8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .submit_button_container {
        position: relative;
        margin-top: 12px;
        text-align: center;
        align-content: center;
        display: block;
        padding: 10px;

        .submit_button {
          background-color: orangered;
          color: white;
          border: 1px solid orangered;
          border-radius: 5px;
          padding: 8px 18px;
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            background-color: #d0410c;
            color: lightgrey;
          }
        }
      }
    }

    .executive,
    .district {
      font-size: 13px;

      .css-yk16xz-control {
        height: 32px !important;
        min-height: auto;
        border: none;
        border-radius: 0px;
        border-bottom: 2px solid #e9e8e8;
        background-color: transparent;

      }

      .css-g1d714-ValueContainer {
        padding: 0px;
      }

      .css-1pahdxg-control {
        box-shadow: none;
        border: none;
        height: 32px !important;
        min-height: auto;
        border-radius: 0px;
        border-bottom: 2px solid #e9e8e8;
        background-color: transparent;

        &:focus-within {
          border-color: #fd7e14;
        }
      }

      .pholder,
      .css-tj5bde-Svg {
        box-shadow: none;
      }

      .css-1okebmr-indicatorSeparator {
        visibility: hidden;
      }
    }

  }

  .table_container {
    position: relative;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    $header-bg: #1465ff29;
    $header-text: #333;
    $border-color: #ddd;
    $row-even-bg: #f9f9f9;
    $row-odd-bg: #fff;

    $fixed-width-1: 100px; // Example width for the first column
    $fixed-width-2: 200px; 
    $padding-cell: 6px;
    $padding-header: 12px;
    font-size: 12px;
    .targets_table {
      width: 100%;
      border-collapse: collapse; 
      font-family: Arial, sans-serif; 
      margin-bottom: 20px;
      thead {
        th {
          background-color: $header-bg;
          color: $header-text;
          padding: $padding-header;
          border-bottom: 2px solid $border-color;
          text-align: left;
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: $row-even-bg;
          }

          &:nth-child(odd) {
            background-color: $row-odd-bg;
          }
        }

        th,
        td {
          padding: $padding-cell;
          border: 1px solid $border-color;
          text-align: left; 
          max-width: 100px;
          &:nth-child(1) {
            width: $fixed-width-1;
          }
          &:nth-child(2) {
            width: $fixed-width-2;
          }
        }

      }
    }

  }

}