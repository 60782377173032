.new_salesorder_container{
  position: relative;
  visibility: hidden;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .sales_order_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .custom_input2{
        position: relative;
        width: 50%;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 1px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
      }
      .input_grid{
        position: relative;
        display: grid;
        grid-template-columns:50% auto;
        grid-column-gap:10px;
      }
    }

    .customer_details_container{
      margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .input{
        outline: none;
        box-shadow: none;
        resize:none;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }

      .input_radio_container{
        position: relative;
        display: grid;
        margin-top: 10px;
        grid-template-columns:max-content auto;
        grid-column-gap:10px;
        align-items:center;
        font-size: 14px;
        color: #617973;
        font-family: "Arial";

        .radio_buttons{
          display: inline-block;
          // grid-template-columns:auto auto;
          // justify-content:left;
          // grid-column-gap:10px;
          // align-items:center;

          .label_radio_container{
            display: inline-block;
            // width: auto;
            // grid-template-columns:max-content max-content;
            // align-items:center;
            // grid-column-gap:4px;
            margin:3px 0px;
            font-size: 12px;
            vertical-align: middle;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
              margin-top: -1px;
              vertical-align: middle;
            }

            .radio_label{
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .billing_details_container{
      padding: 10px;
      box-sizing:border-box;

      .input{
        outline: none;
        box-shadow: none;
        resize:none;
        -moz-appearance: textfield;
      }
    }

    .delivery_details_container{
      padding: 10px;
      box-sizing:border-box;

      .delivery_title_container{
        display: grid;
        grid-template-columns:auto max-content max-content;
        align-items:center;
        gap : 10px;

        .clone_address{
        }
        .same_as_label{
          font-size: 12px;
          color: grey;
        }

      }

     

      .input{
        outline: none;
        box-shadow: none;
        resize:none;
        -moz-appearance: textfield;
      }
      .location{
        font-size: 13px;
          .css-yk16xz-control{
            height: 32px!important;
            min-height: auto;
            border: none;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;

          }

          .css-g1d714-ValueContainer{
            padding: 0px;
          }

          .css-1pahdxg-control{
            box-shadow: none;
            border: none;
            height: 32px!important;
            min-height: auto;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;

            &:focus-within{
              border-color: #fd7e14;
            }
          }

          .pholder,.css-tj5bde-Svg{
            box-shadow: none;
          }

          .css-1okebmr-indicatorSeparator{
            visibility: hidden;
          }
      }
    }

    .products_container{
      padding: 10px;
      box-sizing:border-box;
      .grand_total{
        font-size: 12px;
      }

      .single_product_container{
        position: relative;
        border-bottom: 2px solid #420b3e;
        border-top: 2px solid #420b3e;
        padding: 0px 7px 7px 7px;
        box-sizing:border-box;
        margin-top: 10px;
        // background-color: #e6bee2;
        background:linear-gradient(to bottom right , rgba(255,255,0,0.1), rgba(0,255,255,0.1));
        transition : all 1s;

        .product_label_and_delete_button{
          display: grid;
          height: 26px;
          width:100%;
          grid-template-columns: max-content auto max-content;
          align-items:center;
          // padding: 0px 5px;
          box-sizing:border-box;

          grid-column-gap:5px;

          .product_label{
            font-size: 14px;
            height: 16px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
            color: green;
          }

          .maximize_button{
            display: none;
          }

          .minimize_button,.maximize_button{
            color: #420b3e;
            padding: 3px 3px 3px 0px;
            box-sizing:border-box;
          }

          .delete_product_button{
            justify-self:right;
            color: red;
            box-shadow: none;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;
            transition:all 0.3s;
            // border: 1px solid white;
            // &:hover{
            //   border: 1px solid red;
            // }

          }

        }

        .qty_unit_price_container{
          position: relative;
          display: none;
          grid-template-columns:auto auto auto auto auto;
          font-size: 11px;
          font-weight: 700;


          .product_total{
            // align-self:center;
            // font-size: 12px;
          }
        }

        .input_label{
          margin-top: 6px;
        }
        .sp_label{
          font-size: 10px;
        }

        .product{
          font-size: 13px;
            .css-yk16xz-control{
              height: 32px!important;
              min-height: auto;
              border: none;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

            }

            .css-g1d714-ValueContainer{
              padding: 0px;
            }

            .css-1pahdxg-control{
              box-shadow: none;
              border: none;
              height: 32px!important;
              min-height: auto;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

              &:focus-within{
                border-color: #fd7e14;
              }
            }

            .pholder,.css-tj5bde-Svg{
              box-shadow: none;
            }

            .css-1okebmr-indicatorSeparator{
              visibility: hidden;
            }
        }
        

        .input{
          width:100%;
          outline: none;
          box-shadow: none;
          resize:none;
          -moz-appearance: textfield;
        }

        .unit{
          border: none;
          height: 32px;
          font-size: 12px;
          border:none;
          border-bottom: 2px solid #e9e8e8;
          caret-color: #fd7e14;

          .css-yk16xz-control{
            height: 32px!important;
            min-height: auto;
            border: none;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;
          }

          .css-g1d714-ValueContainer{
            padding: 0px;
            background-color: transparent;

          }

          .css-1pahdxg-control{
            box-shadow: none;
            border: none;
            height: 32px!important;
            min-height: auto;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;

            &:focus-within{
              border-color: #fd7e14;
            }
          }

          .pholder,.css-tj5bde-Svg{
            box-shadow: none;
          }

          .css-1okebmr-indicatorSeparator{
            visibility: hidden;
          }
        }
      }

      .price_and_add_button_container{
        position: relative;
        display: grid;
        grid-template-columns:auto max-content;
        margin-top: 5px;
        align-items:center;

        .add_product_button{
          position: relative;
          justify-self:right;
          padding: 10px 20px;
          background-color: green;
          color: white;
          box-shadow: none;
          border: none;
          border-radius: 25px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;
          &:hover{
            background-color: #002400;
            color: lightgrey;
          }
        }

      }
    }
    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

}