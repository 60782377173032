.sales_return_page_container{
    position: relative;
    margin-top: 60px;
    width: 100%;
    height: auto;
    font-family: "Arial";
  
    .page_header{
      position: relative;
      display: grid;
      grid-template-columns: max-content max-content;
      align-items:center;
      width: 100%;
      padding-top: 5px;
      box-sizing:border-box;
  
      .page_title{
        color: #fd7e14;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
      }
  
      .back_button{
        color: #333;
        font-size: 17px;
        font-weight: 600;
        padding: 5px 8px;
        cursor: pointer;
        text-decoration: none;
      }
    }
  
    .customer_filter_form{
        position: relative;
        width:100%;
        margin-top:10px;
        padding: 0px 10px 10px 10px;
        box-sizing:border-box;
    
        .blocks{
          position: relative;
          width: 100%;
          padding: 10px;
          box-sizing:border-box;
          border-radius: 2px;
          box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);
    
          .input_grid{
            position: relative;
            display: grid;
            
          }
          .form_input{
            position: relative;
            margin-top:0px;
            // grid-template-columns:50% 50%;
            grid-column-gap:10px;
            .input_label{
              position: relative;
              margin-top:8px;
              color: #617973;
              font-size: 14px;
              font-family: "Arial";
            }
            .from_to_label{
              display: grid;
              grid-template-columns:25% auto;
              grid-column-gap:28px;
              .input_label{
                position: relative;
                margin-left: 3px;
                margin-top: 14px;
                color: #617973;
                font-size: 14px;
                font-family: "Arial";
              }

            }
            .custom_input{
              position: relative;
              margin-top:8px;
              font-size: 14px;
            
            }
          }
    
          .submit_button_container{
            position: relative;
            margin-top: 5px;
            text-align: right;
            align-content: flex-end;
            display: grid;
            padding: 10px;
    
            .submit_button{
              background-color: orangered;
              color: white;
              border: 1px solid orangered;
              border-radius: 5px;
              padding: 5px 10px;
              transition: all 0.3s;
              cursor: pointer;
    
              &:hover{
                background-color: #d0410c;
                color: lightgrey;
              }
            }
          }
        }
    
        .customer{
          font-size: 13px;
          .css-yk16xz-control{
            height: 32px!important;
            min-height: auto;
            border: none;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;
    
          }
    
          .css-g1d714-ValueContainer{
            padding: 0px;
          }
    
          .css-1pahdxg-control{
            box-shadow: none;
            border: none;
            height: 32px!important;
            min-height: auto;
            border-radius: 0px;
            border-bottom: 2px solid #e9e8e8;
            background-color: transparent;
    
            &:focus-within{
              border-color: #fd7e14;
            }
          }
    
          .pholder,.css-tj5bde-Svg{
            box-shadow: none;
          }
    
          .css-1okebmr-indicatorSeparator{
            visibility: hidden;
          }
        }
    
      }
    
  .table_container{
    position: relative;
    width:100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    box-sizing:border-box;
    overflow-x: scroll;
    .sales_return_table{
      position: relative;
      width:100%;
      font-size: 10px;
      text-align: left;
      margin-top: 3px;
      border-collapse: collapse;
      border:1px solid grey;
      padding: 0px 20px;

      thead{
        color: white;
        font-size: 14px;
        background-color:#420b3e;

        th{
          border:2px solid grey;
            padding: 5px;
        }
      }

      tbody{
        padding: 5px;
        box-sizing:border-box;
        font-size: 12px;
        color: #333;
        text-shadow: 0px 0px 3px white;

        tr{
          td{
            border:2px solid grey;
            // border:1px solid grey;
            padding: 5px;
            white-space: normal;
            font-weight: 500;

            &:nth-child(2){
              word-break: break-all;
              }

              .credit_limit_period{
              display: grid;
              grid-template-columns:auto auto;
              padding: 6px 0px;
              

              }
          }

          &:nth-child(even){
            background-color: #80808014;
          }
        }
      }
      .finalTotal{
        text-align: right;
        td{
          span{
          font-size: 10px;
          font-weight: 700;
          text-align: right;
          }
        }
      }
    }

    .details_table_container{
      position: relative;
      height: auto;
      overflow: hidden;
      transition: height 0.3s;
      text-shadow: 0px 0px 3px white;


      .details_table{
        border-collapse: collapse;
        border: none;
        width: 100%;
        // background-color: #f4dcdf;
        thead{
          color: #333;
          font-size: 11px;
          background-color: transparent;

          th{
            border: none;
            border-bottom:1px solid #a8a8a8;
            text-align: center;
              padding: 3px 2px;
              // &:nth-child(1){
              //   border-right:1px solid #a8a8a8;
              // }
          }
        }

        tbody{
          padding: 5px;
          box-sizing:border-box;
          font-size: 10px;
          color: #333;
          .cust_total {
            // border-top: 1px solid gray !important;
            .ttl{
              text-align: right;
            }
          }
          tr{
          border:none;

            transition:all .3s;
            td{
              border:none;
              padding: 3px 2px;
              white-space: normal;
              font-weight: 500;
              text-align: center;

              // &:nth-child(1){
              //   border-right:1px solid #a8a8a8;
              // }
            }

            &:nth-child(even){
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  
  }