.rate_calculator_page_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";
 
  overflow: hidden;

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content auto;
    grid-column: auto;
    align-items:center;
    width: 100%;
    padding-top: 10px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }
    .reset_btn {
      padding:2px 4px;
      margin-left:15px;
      max-Width:80px;
      background-color:red;

      display: block;
      position: relative;
      // padding: 2px 12px;
      border-radius: 12px;
      min-width: 10px;
      // font-size: 1rem;
      color: white;
      background: rgb(255, 2, 2);
      border-color: red;
      will-change: transform;
      transform: translateY(-4px);
      transition:
        transform
        600ms
        cubic-bezier(.3, .7, .4, 1);
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
    .submit_button_container{
      position: relative;
      // margin-top: 5px;
      text-align: right;
      align-content: flex-end;
      display: grid;
      padding: 0px 10px;
      margin-left: auto;
      margin-right: 5px;

      .submit_button{
        background-color: orangered;
        color: white;
        border: 1px solid orangered;
        border-radius: 5px;
        padding: 5px 10px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover{
          background-color: #d0410c;
          color: lightgrey;
        }
      }
    }
  }
  .customer_details_container{
    margin-top: 5px;
    padding: 1px 10px 10px 10px;
    box-sizing:border-box;

    .customer_schedule_type_radio_buttons{
      display: grid;
      grid-template-columns:auto auto auto;
      justify-content:center;
      grid-column-gap:10px;
      margin-top: 10px;

      .label_radio_container{
        display: grid;
        grid-template-columns:max-content auto;
        align-items:center;
        grid-column-gap:2px;
        color: #333;

          input[type=radio] {
            width: 18px;
            height: 18px;
            margin:0px;
          }
      }
    }

    .input{
      outline: none;
      box-shadow: none;
      resize:none;

      .react-date-picker__wrapper{
        border:none;

        .react-date-picker__calendar .react-calendar{
          width: 100% !important;
        }

        .react-date-picker__inputGroup__input ::-moz-selection {
          background-color: transparent;
          color: #000;
        }

        .react-date-picker__inputGroup__input::selection {
          background-color: transparent;
          color: #000;
        }
      }
    }

    .add_button_container{
      position: relative;
      margin-top: 8px;
      width: 100%;
      text-align: right;
      
      .add_schedule_button{
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        background-color: green;
        color: white;
        box-shadow: none;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        text-decoration: none;
        transition:all 0.3s;
        &:hover{
          background-color: #002400;
          color: lightgrey;
        }
      }
    }
  }

  .search_container{
    position: relative;
    display: grid;
    grid-template-columns:max-content auto;
    width: 200px;
    height: 38px;
    overflow: hidden;
    border: 1px solid grey;
    border-radius: 25px;
    max-width: 95%;
    margin-left: 10px;
    margin-top: 5px;
    align-items:center;
    color: grey;
    padding-left: 10px;
    box-sizing:border-box;

    .product_search_input{
      padding: 4px 10px;
      box-sizing:border-box;
      width: 100%;
      height: 100%;
    }
  }


  .table_container{
    position: relative;
    width:100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    box-sizing:border-box;
    overflow: scroll;
    scrollbar-width: thin;
    scrollbar-color: orange white;

    .products_price_table{
      position: relative;
      width:100%;
      font-size: 10px;
      text-align: left;
      margin-top: 3px;
      border-collapse: collapse;
      border:1px solid grey;

      thead{
        color: white;
        font-size: 14px;
        background-color:#420b3e;

        th{
          border:2px solid grey;
            padding: 5px;
        }
      }

      tbody{
        padding: 5px;
        box-sizing:border-box;
        font-size: 11px;
        color: #333;
        text-shadow: 0px 0px 3px white;

        tr{
          td{
            border:2px solid grey;
            // border:1px solid grey;
            padding: 5px;
            white-space: normal;
            // font-weight: 500;
            font-size: 10px;

            &:nth-child(2){
              word-break: break-all;
             }
            //  input{
            //   width: 100%;
            //   padding: 5px;
            //   border: none;
            //   background-color: transparent;
            //   text-align: center;
            //   -moz-appearance: textfield;
            //   -webkit-appearance: none;
            //   margin: 0;
            //  input::-webkit-inner-spin-button,
            //  input::-webkit-outer-spin-button {
            //     -webkit-appearance: none;
            //     margin: 0;
            //   }
            //  }
            .table-input {
              width: 100%;
              padding: 5px;
              border: none;
              background-color: transparent;
              text-align: center;
              -moz-appearance: textfield; /* Firefox */
              border-bottom: 1px solid #80097757;
              outline: none;
              box-sizing: border-box;
            }
          
            .table-input::-webkit-inner-spin-button,
            .table-input::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .table-input:focus {
              border-bottom: 2px solid #44053f;
            }
          }

          &:nth-child(even){
            background-color: #80808014;
          }
        }
      }
    }

   
  }

  .terms_and_condtions{
    margin-bottom: 100px;
    padding : 0px 10px;

    .input_terms{
      outline: reset;
      padding:  10 px 20 px; 
      margin : 10px 20px;
      border : 1px solid rebeccapurple;
      box-shadow: 2px rebeccapurple;
      resize:none;
      // height:300px;
      width: 80%;


     
    }
  }

}