.new_schedule_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .schedule_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }

      .custom_input2{
        position: relative;
        width: 50%;
        height: 30px;
        font-size: 14px;
        border:none;
        border: 1px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
      }
    }

    .customer_details_container{
      margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .customer_type_radio_buttons{
        display: grid;
        grid-template-columns:auto auto;
        justify-content:center;
        grid-column-gap:10px;
        margin-top: 10px;

        .label_radio_container{
          display: grid;
          grid-template-columns:max-content auto;
          align-items:center;
          grid-column-gap:2px;
          color: #333;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
            }
        }
      }

      .input{
        outline: none;
        box-shadow: none;
        resize:none;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }

      .add_button_container{
        position: relative;
        margin-top: 8px;
        width: 100%;
        text-align: right;
        
        .add_schedule_button{
          position: relative;
          display: inline-block;
          padding: 10px 20px;
          background-color: green;
          color: white;
          box-shadow: none;
          border: none;
          border-radius: 25px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;
          &:hover{
            background-color: #002400;
            color: lightgrey;
          }
        }
      }
    }

    .schedules_table_container{
      
      table{
        position: relative;
        width: 100%;
        border-collapse: collapse;

        thead{
          font-size: 14px;
        }

        tbody{
          font-size: 12px;
        }

        tr{
          border-bottom: 1px solid lightgrey;

          td,th{
            padding: 10px 3px;

          }
        }

        tbody tr:nth-child(even){
          background-color: #8080802e;
        }

        .remove_schedule_button{
          font-size: 20px;
          color: red;
        }
      }
    }

    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

  .list_blocks_container{
    position: relative;
    width: 100%;
    height: auto;

    .lists_container{
      padding: 10px;
      box-sizing:border-box;
      scrollbar-width: thin;
      scrollbar-color: orange white;


      .single_schedule_container{
        position: relative;
        width:100%;
        margin-bottom: 10px;
        padding: 12px;
        box-sizing:border-box;
        height: auto;
        overflow: hidden;
        // background-color: #d3d3d33d;
        background-color: #ea8000;
        // background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);`
        // background-image: linear-gradient(315deg, #ea8000 0%, #3e4571 74%);
        background-image: linear-gradient(315deg, #ea8000 0%, #420b3e 74%);
        transition: 0.6s height;
        border-radius: 7px;

        .scheduled_customer_name_delete_button_container{
          position: relative;
          width: 100%;
          display: grid;
          grid-template-columns:max-content auto max-content;
          grid-column-gap:10px;
          box-sizing:border-box;

          .scheduled_date_container{
            text-align: center;
            align-self:center;
            border-right: 2px solid white;
            padding: 0px 10px;
            // background-color: black;
            .scheduled_month{
              font-size: 16px;
              font-weight: 600;
              color: white;
            }

            .scheduled_day{
              font-size: 16px;
              font-weight: 600;
              color: white;
            }
          }
          
          .customer_name_check_in_button_container{
            position: relative;
            display: grid;
            align-items:center;
            grid-template-columns: 50% 50%;

            .scheduled_customer_name{
              position: relative;
              font-weight: 500;
              color: white;
              font-size: 16px;
              white-space: pre-line;
              word-break: break-word;
            }

            .schedules_button_container{
              // margin-top: 10px;   
              .check_in_button{
                display: inline-block;
                padding: 5px 10px;
                background-color: white;
                border:2px solid green;
                color: green;
                border-radius: 6px;
                font-size: 14px;
                font-weight: 500;
              }

              .map_button{
                margin-left: 20px;
                display: inline-block;
                padding: 5px 10px; 
                background-color: white;
                border:2px solid #1a73e8;
                color: #1a73e8;
                border-radius: 6px;
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
              }

              .edit_button{
                margin-left: 20px;
                display: inline-block;
                padding: 5px 8px;
                background-color: white;
                border:2px solid #b57741;
                color: #b57741;
                border-radius: 6px;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .schedule_approved{
              padding: 5px 8px;
              // border:1px dashed #22fa51;
              background-color: white;
              color:#006c17;
              border-radius: 8px;
              font-size: 14px;  
              font-weight: bold;
              text-align: center;
  
              .fa-check{
                color: green;
                margin-right: 10px;
              }
            }
            .schedule_requested{
              padding: 5px 8px;
              // border:1px dashed #000000;
              background-color: rgb(255, 255, 255);
              color:rgb(255, 0, 0);
              border-radius: 8px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
  
              .fa-clock-o{
                color: rgb(255, 0, 0);
                margin-right: 10px;
              }
            }
            .schedule_rejected{
              padding: 5px 8px;
              // border:1px dashed #ffffff;
              background-color: rgb(255, 0, 0);
              color:rgb(255, 255, 255);
              border-radius: 8px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
  
              .fa-times{
                color: rgb(255, 255, 255);
                margin-right: 10px;
              }
            }
          }
          .delete_schedule_button{
            font-size: 16px;
            color: white;
          }

        }

      }
    }
  }

}