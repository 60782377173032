.joint_visit_page_container{
  position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
  width: 100%;
  height: 100%;
  font-family: "Arial";
  overflow: hidden;

  .schedules_container{
    position: relative;
    display: grid;
    grid-template-rows: max-content max-content auto;
    width: 100%;
    height: 100%;
    overflow: hidden;

   
    .page_header{
      position: relative;
      width: 100%;
      // height: 50px;
      display: grid;
      grid-template-columns: max-content auto max-content;
      gap:10px;
      background-color: #ea8000;
      // border-bottom: 1px solid #333;
      align-items:center;
      padding:5px 5px 5px 10px;
      box-sizing:border-box;

      .page_title{
        color: white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }

      .new_schedule_button{
        color:white;
        font-size: 14px;
        font-weight: 500;
        font-family: "Arial";
        padding: 7px 16px;
        background-color: #058b00;
        border : 1px solid lightgrey;
        border-radius: 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover{
          background-color: #058b71;
          color: lightgrey;
        }
      }
    }
    .toggles_container{
      position: relative;
      padding:8px auto;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(4, 25%);
      // grid-columns-gap:5px;
      justify-items:center;
      background-color: #d3d3d35e;

      .toggles{
        display: grid;
        width:100%;
        // border:1px solid lightgrey;
        height: 70px;
        justify-items:center;
        grid-template-rows: auto auto auto;
        grid-row-gap:3px;
        align-content:center;
        color:#333;
        transition:all 0.3s;
        cursor: pointer;

        .order_status_icon{
          font-size: 22px;
        }
        
        .order_status{
          font-size: 10px;
          font-weight: 600;
        }

        .order_count{
          font-size: 12px;
          font-weight: 600;
        }
      }

    }

    .list_blocks_container{
      position: relative;
      width: 100%;
      height: auto;

      .lists_container{
        padding: 10px;
        box-sizing:border-box;
        scrollbar-width: thin;
        scrollbar-color: orange white;


        .single_schedule_container{
          position: relative;
          width:100%;
          margin-bottom: 10px;
          padding: 12px;
          box-sizing:border-box;
          height: auto;
          overflow: hidden;
          // background-color: #d3d3d33d;
          background-color: #ea8000;
          // background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);`
          // background-image: linear-gradient(315deg, #ea8000 0%, #3e4571 74%);
          background-image: linear-gradient(315deg, #ea8000 0%, #420b3e 74%);
          transition: 0.6s height;
          border-radius: 7px;

          .scheduled_customer_name_delete_button_container{
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns:max-content auto max-content;
            grid-column-gap:10px;
            box-sizing:border-box;

            .scheduled_date_container{
              text-align: center;
              align-self:center;
              border-right: 2px solid white;
              padding: 0px 10px;
              // background-color: black;
              .scheduled_month{
                font-size: 16px;
                font-weight: 600;
                color: white;
              }

              .scheduled_day{
                font-size: 16px;
                font-weight: 600;
                color: white;
              }
            }
            
            .customer_name_check_in_button_container{
              position: relative;
              display: grid;
              align-items:center;
              // grid-template-rows: auto;
              
              .scheduled_customer_name{
                position: relative;
                font-weight: 500;
                color: white;
                font-size: 16px;
                white-space: pre-line;
                word-break: break-word;
                span{
                  margin-left: 5px;  
                }
              }

              .schedules_button_container{
                margin-top: 5px;   
                display: grid;
                grid-template-columns: max-content auto auto;
                .check_in_button{
                  display: inline-block;
                  padding: 5px 10px;
                  background-color: white;
                  border:2px solid green;
                  color: green;
                  border-radius: 6px;
                  font-size: 14px;
                  font-weight: 500;
                }

                .map_button{
                  margin-left: 20px;
                  display: inline-block;
                  padding: 5px 10px; 
                  background-color: white;
                  border:2px solid #1a73e8;
                  color: #1a73e8;
                  border-radius: 6px;
                  font-size: 14px;
                  font-weight: 500;
                  text-decoration: none;
                }

                .edit_button{
                  margin-left: 20px;
                  display: inline-block;
                  padding: 5px 8px;
                  background-color: white;
                  border:2px solid #b57741;
                  color: #b57741;
                  border-radius: 6px;
                  text-decoration: none;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }

            .status_of_schedules{
              position: relative;
              display: grid;
              align-items:center;
              grid-template-columns: max-content max-content;
              grid-column-gap: 8px;
              margin-top: 6px;

              .schedule_approved{
                padding: 5px 8px;
                // border:1px dashed #22fa51;
                background-color: white;
                color:#006c17;
                border-radius: 8px;
                font-size: 12px;  
                font-weight: bold;
                text-align: center;
    
                .fa-check{
                  color: green;
                  margin-right: 10px;
                }
              }
              .schedule_requested{
                padding: 5px 8px;
                // border:1px dashed #000000;
                background-color: rgb(255, 255, 255);
                color:rgb(255, 0, 0);
                border-radius: 8px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
    
                .fa-clock-o{
                  color: rgb(255, 0, 0);
                  margin-right: 10px;
                }
              }
              .schedule_rejected{
                padding: 5px 8px;
                // border:1px dashed #ffffff;
                background-color: rgb(255, 0, 0);
                color:rgb(255, 255, 255);
                border-radius: 8px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
    
                .fa-times{
                  color: rgb(255, 255, 255);
                  margin-right: 10px;
                }
              }
              .deviation_from_schedule{
                padding: 5px 8px;
                // border:1px dashed #ffffff;
                background-color: rgb(255, 0, 0);
                color:rgb(255, 255, 255);
                border-radius: 8px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;

              }
              .deviation_marked_schedule{
                padding: 5px 8px;
                // border:1px dashed #ffffff;
                background-color: rgb(255, 0, 0);
                color:rgb(255, 255, 255);
                border-radius: 8px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;

              }

            }
            .delete_schedule_button{
              font-size: 16px;
              color: white;
            }

          }

        }
      }
    }

    .close_form_ghost, .deviation_remarks_form_ghost{
      position: fixed;
      display: none;
      z-index: 2023;
      top:0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3);

      .close_form, .deviation_form{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        background-color: white;
        width:400px;
        max-width: 95%;
        padding: 20px;
        box-sizing:border-box;
        height: auto;

        .close_button{
          position: absolute;
          top: 8px;
          right:8px;
          color: black;
        }

        .input_label{
          position: relative;
          margin-top:8px;
          color: #617973;
          font-size: 14px;
          font-family: "Arial";
        }

        .custom_input{
          position: relative;
          width: 100%;
          height: 30px;
          font-size: 14px;
          border:none;
          border-bottom: 2px solid #e9e8e8;
          caret-color: #fd7e14;
          font-family: "Arial";
          background-color: transparent;
          outline: none;
          box-shadow: none;
          resize:none;


          &:focus-within{
            border-color: #fd7e14;
          }
        }
        .confirm_deviation_button{
          position: relative;
          display: block;
          margin-top: 10px;
          // width: 250px;
          padding: 7px 15px;
          background-color: #1a73e9;
          color: white;
          box-shadow: none;
          border: none;
          font-size: 13px;
          border-radius: 5px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;

          &:hover{
            background-color: #3e70e8;
            color: lightgrey;
          }
        }

        .confirm_reorder_button{
          position: relative;
          display: block;
          margin-top: 10px;
          // width: 250px;
          padding: 7px 15px;
          background-color: red;
          color: white;
          box-shadow: none;
          border: none;
          font-size: 13px;
          border-radius: 5px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;

          &:hover{
            background-color: #d20606;
            color: lightgrey;
          }
        }
      }
    }
  }

}