
.dashboard-tile {
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  background-color: #bf1d1d;
  color: white;
  box-shadow: 0 0 5px rgba(241, 15, 15, 0.451);
  border-radius: 20px;
  margin: 20px 25px;
  position: relative;
  height: 54px;

  text-align: center; /* Center-align the text content */
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  .title_dashboard{
    font-size: 14px;
    font-weight: 800;
    display: inline-block; 
  }
  
  p {
    font-size: 16px;
    margin: 0px;
    color: #007bff; 
    position: absolute;
    bottom: 5px;
    width: 100%; 
    padding: 0px;
  }
}