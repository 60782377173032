.sales_orders_page_container{
	position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
	width: 100%;
	height: 100%;
	font-family: "Arial";
  overflow: hidden;

  .sales_orders_container{
    position: relative;
    display: grid;
    grid-template-rows: max-content max-content auto;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .page_header{
      position: relative;
      width: 100%;
      // height: 50px;
      display: grid;
      grid-template-columns: auto max-content;
      background-color: #ea8000;
      // border-bottom: 1px solid #333;
      align-items:center;
      padding:5px 5px 5px 10px;
      box-sizing:border-box;

      .page_title{
        color: white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }

      .new_sales_order_button{
        color:white;
        font-size: 14px;
        font-weight: 500;
        font-family: "Arial";
        padding: 7px 16px;
        background-color: #058b00;
        border : 1px solid lightgrey;
        border-radius: 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover{
          background-color: #058b71;
          color: lightgrey;
        }
      }
    }

    .toggles_container{
      position: relative;
      padding:8px auto;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(3, 33.3%);
      // grid-columns-gap:5px;
      justify-items:center;
      background-color: #d3d3d35e;

      .toggles{
        display: grid;
        width:100%;
        // border:1px solid lightgrey;
        height: 70px;
        justify-items:center;
        grid-template-rows: auto auto auto;
        grid-row-gap:3px;
        align-content:center;
        color: #333;
        transition:all 0.3s;
        cursor: pointer;

        .order_status_icon{
          font-size: 22px;
        }
        
        .order_status{
          font-size: 10px;
          font-weight: 600;
        }

        .order_count{
          font-size: 12px;
          font-weight: 600;
        }
      }
     
    }

    .list_blocks_container{
      position: relative;
      width: 100%;
      height: auto;

      .lists_container{
        padding: 10px;
        box-sizing:border-box;
        scrollbar-width: thin;
        scrollbar-color: orange white;

        .single_so_container{
          position: relative;
          width:100%;
          margin-bottom: 10px;
          padding: 4px 8px 8px 8px;
          box-sizing:border-box;
          height: 50px;
          border-bottom: 2px solid #420b3e;
          overflow: hidden;
          background-color: #d3d3d33d;
          transition: 0.6s height;
          text-shadow: 0px 0px 3px white;
          padding-top: 10px;

          .so_details_container{
            position: relative;
            display: grid;
            grid-template-columns:auto max-content max-content max-content max-content;
            width:100%;

            .so_customer_name{
              position: relative;
              font-weight: 500;
              color: #ff3000;
              font-size: 14px;
              white-space: pre-line;

            }

            .so_order_date, .so_delivery_date{
              font-size: 14px;
            }

            .so_approve_button{
              align-self:center;
              color: #2eaa2e;;
              cursor: pointer;
              font-size: 18px;

              i{
                margin-left: 20px;
              }

            }

            .so_edit_button{
              align-self:center;
              color:#1a73e9;
              cursor: pointer;
              font-size: 17px;
              i{
                margin-left: 20px;
              }
            }

            .asm_reject_button{
              align-self:center;
              color: red;
              cursor: pointer;
              font-size: 18px;
              i{
                margin-left: 20px;
              }
            }

            .so_products_button{
              align-self:center;
              color: #333;
              cursor: pointer;
              font-size: 29px;
              justify-self:right;
              .arrow_down{
                margin: 3px 3px 0px 15px;
                transition:all .5s;
              }
            }
          }

          .so_products_container{
            position: relative;
            width:100%;
            font-size: 10px;
            text-align: left;

            thead{
              color: #fd7e14;
              font-size: 10px;
            }

            tbody{
              padding: 5px;
              box-sizing:border-box;
              font-size: 10px;
              tr{
                td{
                   white-space: normal;
                   &:nth-child(2){
                    word-break: break-all;
                   }
                }
              }
            }
          }
        }
      }
    }

    .close_form_ghost{
      position: fixed;
      display: none;
      z-index: 2023;
      top:0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3);

      .close_form{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        background-color: white;
        width:400px;
        max-width: 95%;
        padding: 20px;
        box-sizing:border-box;
        height: auto;

        .close_button{
          position: absolute;
          top: 8px;
          right:8px;
          color: black;
        }

        .input_label{
          position: relative;
          margin-top:8px;
          color: #617973;
          font-size: 14px;
          font-family: "Arial";
        }

        .custom_input{
          position: relative;
          width: 100%;
          height: 30px;
          font-size: 14px;
          border:none;
          border-bottom: 2px solid #e9e8e8;
          caret-color: #fd7e14;
          font-family: "Arial";
          background-color: transparent;
          outline: none;
          box-shadow: none;
          resize:none;


          &:focus-within{
            border-color: #fd7e14;
          }
        }
        .confirm_reject_button{
          position: relative;
          display: block;
          margin-top: 10px;
          // width: 250px;
          padding: 7px 15px;
          background-color: #1a73e9;
          color: white;
          box-shadow: none;
          border: none;
          font-size: 13px;
          border-radius: 5px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;

          &:hover{
            background-color: #3e70e8;
            color: lightgrey;
          }
        }
      }
    }

   

  }

}