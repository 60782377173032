.travel_allowance_page_container{
   position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
  width: 100%;
  height: 100%;
  font-family: "Arial";

  .travel_allowances_container{
    position: relative;
    display: grid;
    grid-template-rows: max-content max-content auto;
    width: 100%;
    height: 100%;

   
    .page_header{
      position: relative;
      width: 100%;
      // height: 50px;
      display: grid;
      grid-template-columns: auto max-content;
      background-color: #ea8000;
      // border-bottom: 1px solid #333;
      align-items:center;
      padding:5px 5px 5px 10px;
      box-sizing:border-box;

      .page_title{
        color: white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }

      .new_travel_allowance_button{
        color:white;
        font-size: 14px;
        font-weight: 500;
        font-family: "Arial";
        padding: 7px 16px;
        background-color: #058b00;
        border : 1px solid lightgrey;
        border-radius: 15px;
        cursor: pointer;
        text-decoration: none;

        &:hover{
          background-color: #058b71;
          color: lightgrey;
        }
      }
    }

    .current_month{
      margin-top: 10px;
      justify-self:center;
      font-size: 16px;
      font-weight: 600;
      color: #3e4571;
    }


    .list_blocks_container{
      position: relative;
      width: 100%;
      height: auto;
      // scrollbar-width: thin;
      //   scrollbar-color: orange white;
      //   overflow-y: scroll;

      .lists_container{
        padding: 10px;
        box-sizing:border-box;
        


        .single_collection_container{
          position: relative;
          display: block;
          width:100%;
          margin-bottom: 10px;
          padding: 12px;
          box-sizing:border-box;
          height: auto;
          overflow: hidden;
          // background-color: #0080003d;
          // background-color: #ea8000;
          // background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);`
          // background-image: linear-gradient(315deg, #ea8000 0%, #3e4571 74%);
          background-image: linear-gradient(315deg, #ea8000 0%, #420b3e 74%);
          transition: 0.6s height;
          // border-radius: 7px;
          border-radius: 5px;
          box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

          .collection_customer_name_delete_button_container{
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns:max-content auto max-content;
            grid-column-gap:10px;
            box-sizing:border-box;

            .collection_date_container{
              text-align: center;
              display: flex;
              align-items:center;
              border-right: 2px solid #9c4a1d;
              padding: 0px 15px 0px 6px;
              height: 100%;
              // background-color: black;
              .collection_no{
                font-size: 26px;
                font-weight: 600;
                color: white;
              }

            }
            .collection_customer_name{
                position: relative;
                font-weight: 500;
                color: white;
                font-size: 18px;
                white-space: pre-line;
                color: white;
                text-shadow: 0px 0px 1px white;
              }
            
            .collection_details_status_button_container{
              position: relative;
              display: grid;
              grid-template-columns:auto max-content;
              align-items:center;
              

              .receipt_no, .col_date, .amount{
                color: white;
                font-size: 16px;
              }

              .amount{
                font-weight: 600;
                // color:black;
                // text-shadow:0px 0px 1px white;
              }

              .collection_status{
                padding: 5px 8px;
                border: 2px solid #b57741;
                color: #065b10;
                border-radius: 8px;
                background-color: white;
                font-size: 14px;

              }

              .edit_button{
                margin-left: 20px;
                display: inline-block;
                padding: 5px 8px;
                background-color: white;
                border:2px solid #b57741;
                color: #b57741;
                border-radius: 6px;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .app{
              font-size: 16px;
              color: white;
            }

          }

        }
      }
    }

  }

}